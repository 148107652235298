<template>
    <figure class="s-video" @mouseenter="mouseEnter" @mousemove="mouseMove" @mouseleave="mouseLeave" v-on:click="openPop()">
        <div class="s-video-badge" id="s-video-badge" style="transition:all ease 0.2s">
            <div class="c-video"></div>
        </div> 
        <video class="s-video-preview" autoplay muted loop>
            <source :src="item.video_link" type="video/mp4">
        </video>
        <div v-if="popUp" class="popup"  v-on:click="openPop()">
            <a v-on:click="openPop()">X</a>
            <iframe class="video" :src="item.video_link" frameborder="0"></iframe>
        </div>
    </figure>
</template>

<script>
export default {
    props: ["item"],
    data(){
        return{
            popUp: false
        }
    },
    mounted(){
        var x = window.matchMedia("(max-width: 579px)")
        x.addListener(myFunction(x))
        function myFunction(x){
            if (x.matches) { 
                document.getElementById('s-video-badge').style.transform = 'translate3d(160px, 60px, 0px)';
            }
            else{
                document.getElementById('s-video-badge').style.transform = 'translate3d('+650+'px, '+300+'px, 0px)';
            }
        }
    },
    methods:{
        mouseEnter(event) {
            this.$el.addEventListener('mousemove', this.mouseMove, false);
        },
        mouseLeave(event) {
            var x = window.matchMedia("(max-width: 579px)")
            x.addListener(myFunction(x))
            function myFunction(x){
                if (x.matches) { 
                    document.getElementById('s-video-badge').style.transform = 'translate3d(160px, 60px, 0px)';
                }
                else{
                    document.getElementById('s-video-badge').style.transform = 'translate3d('+650+'px, '+300+'px, 0px)';
                }
            }
        },
        mouseMove(event) {
            var x = window.matchMedia("(max-width: 579px)")
            x.addListener(myFunction(x))
            function myFunction(x){
                if (x.matches) { 
                    document.getElementById('s-video-badge').style.transform = 'translate3d(160px, 60px, 0px)';
                }
                else{
                    document.getElementById('s-video-badge').style.transform = 'translate3d('+(event.clientX - 35)+'px , '+(event.clientY - 60)+'px, 0px)';
                    // document.getElementById('s-video-badge').style.transform = 'translate3d('+650+'px, '+300+'px, 0px)';
                }
            }
        },
        openPop(){
            if(!this.popUp){
                this.popUp = true
            }else{
                this.popUp = false
            }
        },
        init(){
        }
    },
    created(){
        this.init()
    }
}
</script>

<style>
.s-video-preview{

}
.s-video .popup{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0%;
    left: 0;
    background-color: #000;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
}
.s-video .popup .video{
    width: 100%;
    height: 90vh;
    z-index: 9;
}
.s-video .popup a{
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    margin: 20px;
    font-size: 16px;
    color: #000;
    background-color: #fff;
    border-radius: 50%;
    font-weight: 700;
    box-shadow: 0px 0px 18px -2px #ccc;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>